import React, { useState, useEffect } from "react";
import UserList from "./UserList";
import ChatWindow from "./ChatWindow";
import { useSocket } from "../../SocketProvider";
import { Box, Typography, Paper } from "@mui/material";

const ChatPage = () => {
    const [users, setUsers] = useState([]);
    const [adminUsers, setAdminUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const socket = useSocket(); // Get socket instance from context
    const [unreadMessages, setUnreadMessages] = useState({});

    useEffect(() => {
        fetch(`https://api.admin.hirio.app/api/users/get-all-users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${localStorage.getItem("token")}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                let userData = data?.users.filter((user) => user.role === "admin");
                console.log(userData)
                userData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                setAdminUsers(userData);
            });

        if (socket) {
            socket.on("new_message_notification", (userId) => {
                setUsers((prevUsers) =>
                    prevUsers.map((user) =>
                        user.id === userId ? { ...user, newMessage: true } : user
                    )
                );
            });
            return () => {
                socket.off("new_message_notification");
            };
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            socket.on("receiveSupportMessage", (message) => {
                if (selectedUser?.id !== message.senderId) {
                    setUnreadMessages((prev) => ({
                        ...prev,
                        [message.senderId]: (prev[message.senderId] || 0) + 1, // Increment unread count
                    }));
                }

                setUsers((prevUsers) =>
                    prevUsers.map((user) =>
                        user.id === message.senderId
                            ? { ...user, newMessage: selectedUser?.id !== message.senderId }
                            : user
                    )
                );
            });

            return () => {
                socket.off("receiveSupportMessage");
            };
        }
    }, [socket, selectedUser]);
    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setUsers((prevUsers) =>
            prevUsers.map((u) => (u.id === user.id ? { ...u, newMessage: false } : u))
        );
        setUnreadMessages((prev) => ({
            ...prev,
            [user.id]: 0, // Reset unread count when chat is opened
        }));
    };

    return (
        <Box display="flex" height="100vh"
            sx={{
                paddingBottom: "1rem",
                paddingTop: "3rem",
            }}
        >
            <UserList users={adminUsers} onSelectUser={handleSelectUser} unreadMessages={unreadMessages} />
            {selectedUser ? (
                <ChatWindow selectedUser={selectedUser} socket={socket} />
            ) : (
                <Paper
                    sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", color: "text.secondary" }}
                >
                    <Typography variant="h6">Select a user to start chatting</Typography>
                </Paper>
            )}
        </Box>
    );
};

export default ChatPage;
