import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Divider,
  Button,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OnBoardingApi } from "../../../../apis";
import ExperienceForm from "../../../../components/GlobalComponent/ExperienceForm";
import ProfessionApi from "../../../../apis/routes/professionRoutes";
import { useTranslation } from "react-i18next";
import ProfessionSpecialtySelect from "../../../../components/CustomSelector/ProfessionSpecialtySelect";

const Preferences = ({ formik }) => {
  const [loading, setLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const { t } = useTranslation();
  const [professions, setProfessions] = useState({});
  const [selectedProfession, setSelectedProfession] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [error, setError] = useState(null);
  const fetchProfessions = async () => {
    try {
      setLoading(true);
      const response = await ProfessionApi.getAllProfessions();
      const professionList = response.data?.subcategories || {};
      setProfessions(professionList);
    } catch (err) {
      setError("Failed to load professions.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProfessions();
  }, []);

  const handleSpecialtyChange = (event) => {
    const selected = event.target.value;
    setSelectedProfession(selected);
    if (selected && professions[selected]) {
      setSpecialties(professions[selected]);
    }
    // Update formik value for professional_area
    formik.setFieldValue("preferences.professional_area", selected);
  };

  const handleSpecialtySelect = (specialty) => {
    // You can add your own logic to handle the selected specialty
  };

  // Handle Subcategory Chip Click
  const handleChipClick = (subcategory) => {
    // Get the current experience years for the selected specialty
    const currentExperience =
      formik.values.preferences.experienceYears[selectedSpecialty] || {};

    // Toggle the clicked subcategory experience
    const updatedExperience = {
      ...currentExperience,
      [subcategory]: !currentExperience[subcategory], // Toggle the current value
    };

    // Update Formik's subcategory state dynamically
    formik.setFieldValue(
      `preferences.experienceYears.${selectedSpecialty}`,
      updatedExperience
    );
  };

  useEffect(() => {
    const fetchOnBoardedUserData = async () => {
      try {
        const res = await OnBoardingApi.getOnBoarding();

        function multiParse(input) {
          let result = input;
          try {
            while (typeof result === "string") {
              result = JSON.parse(result);
            }
          } catch (e) {
            console.error("Error parsing input:", e);
          }
          return result;
        }

        if (res.data && res.data.profile) {
          const parsedTopSpecialities = multiParse(
            res?.data?.profile?.top_specialities || "{}"
          );

          const parsedEmployementType = multiParse(
            res?.data?.profile?.employment_type || {}
          );
          const salaryRange = multiParse(
            res?.data?.profile?.salary_range || "{}"
          );
          const workExperience = multiParse(
            res?.data?.profile?.work_experience || "[]"
          );
          const education = multiParse(res?.data?.profile?.education || "[]");
          const languages = multiParse(res?.data?.profile?.languages || "[]");
          const experienceYears = multiParse(
            res?.data?.profile?.experienceYears || "[]"
          );

          const remoteWorkValue = res?.data?.profile?.remote_work ? "1" : "0";
          const relocationValue = res?.data?.profile?.relocation ? "1" : "0";

          // Corrected logic for filtering and mapping the specialties
          const trueTopSpecialities = Object.entries(parsedTopSpecialities)
            .filter(([_, value]) => value) // Keep only entries where value is true
            .map(([key]) => key) // Extract the key (specialty name)
            .join(", "); // Join the names into a single string

          // Result
          formik.setValues({
            ...formik.values,
            preferences: {
              professional_area: res?.data?.profile?.professional_area || "",
              total_years_experience:
                res?.data?.profile.total_years_experience || "",
              // top_specialities: trueTopSpecialities|| [],
              top_specialities: parsedTopSpecialities,
              experienceYears: experienceYears || {},
              employment_type: {
                fullTime: parsedEmployementType?.fullTime || false,
                partTime: parsedEmployementType?.partTime || false,
              },
              job_search_status: res?.data?.profile?.job_search_status || "",
              remote_work: remoteWorkValue || "",
              relocation: relocationValue || "",
            },
            about_you: {
              resume_url: res?.data?.profile.resume_url || "",
              profile_photo_url: res?.data?.profile?.profile_photo_url || "",
              full_name: res?.data?.profile?.full_name || "",
              phone_number: res?.data?.profile?.phone_number || "",
              whatsapp_number: res?.data?.profile?.whatsapp_number || "",
              current_city: res?.data?.profile?.current_city || "",
              salary_range: {
                from: salaryRange?.from || "",
                to: salaryRange?.to || "",
              },
              linkedin_url: res?.data?.profile?.linkedin_url || "",
              personal_blog_url: res?.data?.profile?.personal_blog_url || "",
            },
            background: {
              work_experience:
                workExperience?.length > 0
                  ? workExperience
                  : [
                      {
                        company_name: workExperience?.company_name || "",
                        job_title: workExperience?.job_title || "",
                        is_current: workExperience?.is_current || false,
                        start: workExperience?.start || "",
                        end: workExperience?.end || "",
                        description: workExperience?.description || "",
                      },
                    ],
              education:
                education?.length > 0
                  ? education
                  : [
                      {
                        university_or_collage:
                          education?.university_or_collage || "",
                        graduation_year: education?.graduation_year || "",
                        field_of_study: education?.field_of_study || "",
                        degree: education?.degree || "",
                      },
                    ],
              languages:
                languages?.length > 0
                  ? languages
                  : [
                      {
                        language: languages?.language || "",
                        language_level: languages?.language_level || "",
                      },
                    ],
              objective: res?.data?.profile?.objective || "",
              achievements: res?.data?.profile?.achievements || "",
              personal_characteristics:
                res?.data?.profile?.personal_characteristics || "",
            },
          });
        } else {
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching on-boarded user data:", error);
        setLoading(false);
      }
    };

    fetchOnBoardedUserData();
  }, []);

  const navigate = useNavigate();
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async (values) => {
    try {
      // Convert nested objects to JSON strings
      const updatedValues = {
        ...values,
        preferences: {
          ...values.preferences,
          top_specialities: values.preferences?.top_specialities
            ? JSON.stringify(values.preferences.top_specialities)
            : "{}",
          experienceYears: values.preferences?.experienceYears
            ? JSON.stringify(values.preferences.experienceYears) // Ensure experienceYears are captured correctly
            : "{}",
        },
        // employment_type: values.preferences?.employment_type
        //   ? JSON.stringify(values.preferences.employment_type)
        //   : {},
        background: {
          ...values.background,
          work_experience: JSON.stringify(values.background.work_experience),
          education: JSON.stringify(values.background.education),
          languages: JSON.stringify(values.background.languages),
        },
        about_you: {
          ...values.about_you,
          salary_range: JSON.stringify(values.about_you.salary_range),
        },
      };

      const res = await OnBoardingApi.OnBoarding(updatedValues);
    } catch (err) {
      console.error(err);
    }
  };

  const getSpecialityColor = (speciality) => {
    return speciality === "Select Speciality" ? "#aaa" : "black";
  };
  const trueTopSpecialities = Object.entries(
    formik.values.preferences.top_specialities
  )
    .filter(([_, value]) => value)
    .map(([key]) => key)
    .join(", ");

  const formattedSpeciality = trueTopSpecialities
    ? trueTopSpecialities.replace(/([A-Z])/g, " $1").trim() // Add space between words
    : "Select Speciality";

  const specialityColor = getSpecialityColor(formattedSpeciality);

  const handleEmploymentTypeChange = (event) => {
    const { name, checked } = event.target;

    formik.setFieldValue(`preferences.employment_type.${name}`, checked);

    const otherEmploymentType = name === "partTime" ? "fullTime" : "partTime";

    if (checked) {
      formik.setFieldValue(
        `preferences.employment_type.${otherEmploymentType}`,
        false
      );
    }
  };

  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const experienceList = [
    t("recruiter.create-job-post.experience.Less than 1 year"),
    t("recruiter.create-job-post.experience.1-3 years"),
    t("recruiter.create-job-post.experience.3-5 years"),
    t("recruiter.create-job-post.experience.5+ years"),
  ];
  const designList = [
    "Software Engineer",
    "Business Analyst",
    "Product Manager",
  ];
  const jobStatusList = [
    t("job_status.Not_change_jobs"),
    t("job_status.Actively looking for a job"),
    t("job_status.Currently employed and not looking"),
  ];

  return (
    <Box>
      <Box
        sx={{
          background: "#FFFFFF",
          borderRadius: "14px",
          padding: "0 150px",
        }}
      >
        {/* Qualification Section */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              marginBottom: "15px",
            }}
          >
            {t("onboarding.preferences.qualification")}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ProfessionSpecialtySelect
              selectedSpecialty={handleSpecialtySelect}
              specialties={specialties}
              selectedProfession={selectedProfession}
              handleSpecialtyChange={handleSpecialtyChange}
              professionList={professions}
              loading={loading}
              error={error}
              getSpecialityColor={getSpecialityColor}
              formik={formik}
              module="employee"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                color: "text.primary",
              }}
            >
              {t("onboarding.preferences.total_years_experience")}
            </InputLabel>
            <FormControl fullWidth>
              <Select
                name="preferences.total_years_experience"
                value={formik.values.preferences.total_years_experience}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) =>
                  selected || (
                    <span style={{ color: "#aaa" }}>
                      {t("onboarding.preferences.select_experience")}
                    </span>
                  )
                }
              >
                {experienceList.map((experience) => (
                  <MenuItem key={experience} value={experience}>
                    {experience}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.preferences?.total_years_experience &&
                formik.errors.preferences?.total_years_experience && (
                  <Typography variant="caption" color="error">
                    {formik.errors.preferences.total_years_experience}
                  </Typography>
                )}
            </FormControl>
          </Grid>
        </Grid>

        {/* Employment Type Section */}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <InputLabel
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                color: "text.primary",
              }}
            >
              {t("onboarding.preferences.employment_type")}
            </InputLabel>
            <Box style={{ display: "flex", flexWrap: "wrap" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="partTime"
                    checked={formik.values.preferences.employment_type.partTime}
                    onChange={handleEmploymentTypeChange}
                    sx={{
                      color: "gray",
                      "&.Mui-checked": { color: "secondary.main" },
                    }}
                  />
                }
                label={t("onboarding.preferences.part_time")}
                sx={{ mr: 5, mb: 1 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="fullTime"
                    checked={formik.values.preferences.employment_type.fullTime}
                    onChange={handleEmploymentTypeChange}
                    sx={{
                      color: "gray",
                      "&.Mui-checked": { color: "secondary.main" },
                    }}
                  />
                }
                label={t("onboarding.preferences.full_time")}
                sx={{ mr: 5, mb: 1 }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Job Search Status Section */}
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                color: "text.primary",
              }}
            >
              {t("onboarding.preferences.job_search_question")}
            </InputLabel>
            <FormControl fullWidth>
              <Select
                name="preferences.job_search_status"
                value={formik.values.preferences.job_search_status}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) =>
                  selected || (
                    <span style={{ color: "#aaa" }}>
                      {t("onboarding.preferences.select_status")}
                    </span>
                  )
                }
              >
                <MenuItem value="" disabled>
                  <em>{t("onboarding.preferences.select_status")}</em>
                </MenuItem>
                {jobStatusList.map((jobStatus) => (
                  <MenuItem key={jobStatus} value={jobStatus}>
                    {/* {t(`job_status.${jobStatus}`)} */}
                    {jobStatus}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.preferences?.job_search_status &&
                formik.errors.preferences?.job_search_status && (
                  <Typography variant="caption" color="error">
                    {formik.errors.preferences.job_search_status}
                  </Typography>
                )}
            </FormControl>
          </Grid>
        </Grid>

        {/* Location Preferences Section */}
        <Box mt={3}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              marginBottom: "15px",
            }}
          >
            {t("onboarding.preferences.location")}
          </Typography>

          <InputLabel
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
            }}
          >
            {t("onboarding.preferences.remote_work_question")}
          </InputLabel>
          <RadioGroup
            name="preferences.remote_work"
            value={formik.values.preferences.remote_work}
            onChange={(event) =>
              formik.setFieldValue(
                "preferences.remote_work",
                event.target.value
              )
            }
            sx={{ flexDirection: "row", mb: 2 }}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "secondary.main" },
                  }}
                />
              }
              label={t("common.yes")}
            />
            <FormControlLabel
              value="0"
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "secondary.main" },
                  }}
                />
              }
              label={t("common.no")}
            />
          </RadioGroup>

          <InputLabel
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
            }}
          >
            {t("onboarding.preferences.relocation")}
          </InputLabel>
          <RadioGroup
            name="preferences.relocation"
            value={formik.values.preferences.relocation}
            onChange={(event) =>
              formik.setFieldValue("preferences.relocation", event.target.value)
            }
            sx={{ flexDirection: "row" }}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "secondary.main" },
                  }}
                />
              }
              label={t("common.yes")}
            />
            <FormControlLabel
              value="0"
              control={
                <Radio
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "secondary.main" },
                  }}
                />
              }
              label={t("common.no")}
            />
          </RadioGroup>
        </Box>

        {/* Submit Button */}
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            type="submit"
            variant="contained"
            onClick={async () => {
              const errors = await formik.validateForm();
              if (Object.keys(errors).length === 0) {
                try {
                  await submitForm(formik.values);
                  navigate("/v1/employee/about-you", {
                    state: { formikValues: formik.values },
                  });
                } catch (error) {
                  console.error("Error submitting form:", error);
                }
              } else {
                formik.setTouched({
                  preferences: {
                    ...formik.touched.preferences,
                  },
                });
              }
            }}
            sx={{
              backgroundColor: "secondary.main",
              color: "white",
              "&:hover": { backgroundColor: "secondary.dark" },
            }}
          >
            {t("onboarding.preferences.next_step")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Preferences;
