import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Paper,
  Divider,
} from "@mui/material";

const UserList = ({ users, onSelectUser, selectedUser, unreadMessages }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter users based on search term
  const filteredUsers = users.filter((user) =>
    user.role === "admin" ? "Admin" : (user.full_name ?? "User").toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Paper
      sx={{
        width: "30%",
        height: "calc(100vh - 1rem)",
        overflowY: "auto",
        borderRight: "1px solid #ccc",
      }}
      elevation={3}
    >
      <Typography variant="h6" sx={{ p: 2, bgcolor: "grey.200" }}>
        Users
      </Typography>

      {/* Search Input */}
      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      <List>
        {filteredUsers.length > 0 ? (
          filteredUsers.map((user) => (
            <React.Fragment key={user.id}>
              <ListItem
                button
                onClick={() => onSelectUser(user)}
                sx={{
                  bgcolor: selectedUser?.id === user.id ? "primary.light" : "inherit",
                  "&:hover": { bgcolor: "blue.100" },
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "primary.main" }}>
                    {user?.role === "admin"
                      ? "A"
                      : user?.full_name?.charAt(0).toUpperCase() ?? "U"}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                 sx={{
                  display: "flex",
                  justifyContent:"space-between"
                 }}
                  primary={user?.role === "admin" ? "Admin" : user?.full_name ?? "User"}
                  secondary={
                    <Box >
                      <Typography variant="body2">{user.username}</Typography>
                      {unreadMessages[user.id] > 0 && (
                        <Box
                          sx={{
                            display: "inline-block",
                            bgcolor: "red",
                            color: "white",
                            borderRadius: "50%",
                            width: 20,
                            height: 20,
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          {unreadMessages[user.id]}
                        </Box>
                      )}
                    </Box>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <Typography variant="body2" sx={{ p: 2, color: "text.secondary" }}>
            No users found
          </Typography>
        )}
      </List>
    </Paper>
  );
};

export default UserList;
