import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, Paper } from "@mui/material";
import { ChatApi } from "../../apis";

const ChatWindow = ({ selectedUser, socket }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const userId = JSON.parse(localStorage.getItem("user")).id;

  // Fetch chat messages when a user is selected
  useEffect(() => {
    if (selectedUser) {
      ChatApi.getSupportMessages(selectedUser.id)
        .then((res) => {
          setMessages(res?.data?.data || []);
        })
        .catch((err) => console.log("Error fetching messages:", err));
    }
  }, [selectedUser]);

  // Listen for incoming messages
  useEffect(() => {
    socket.on("receiveSupportMessage", (message) => {
      setMessages((prev) => [...prev, message]);
    });

    return () => {
      socket.off("receiveSupportMessage");
    };
  }, [socket]);

  const sendMessage = () => {
    if (newMessage.trim() !== "") {
      const message = {
        senderId: userId,
        receiverId: selectedUser.id,
        message: newMessage,
      };
      socket.emit("sendSupportMessage", message);
      setMessages((prev) => [...prev, message]);
      setNewMessage("");
    }
  };

  return (
    <Paper elevation={3} sx={{ width: "66.66%", display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* Chat Header */}
      <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider", backgroundColor: selectedUser.role === "admin" ? "#031141" : "#8EFFC2" }}>
        <Typography variant="h6" sx={{ color: selectedUser.role === "admin" ? "white" : "black" }}>
          Chat with: {selectedUser.role === "admin" ? "Admin" : selectedUser.full_name ?? "Admin"}
        </Typography>
      </Box>

      {/* Chat Messages */}
      <Box sx={{ flex: 1, overflowY: "auto", p: 2, bgcolor: "grey.50", display: "flex", flexDirection: "column" }}>
        {messages.map((msg, index) => {
          const isSender = msg.senderId === userId;

          return (
            <Box
              key={index}
              sx={{
                maxWidth: "70%",
                width: "fit-content",
                alignSelf: isSender ? "flex-end" : "flex-start",
                bgcolor: isSender ? "#1E1E2F" : "grey.300",
                color: isSender ? "white" : "black",
                p: 1.5,
                mb: 1,
                borderRadius: isSender ? "12px 12px 0px 12px" : "12px 12px 12px 0px",
                // if the text is lengthy move it to the next line
                whiteSpace: "normal", // Ensures text wraps
                wordBreak: "break-word", // Allows long words to break
                overflowWrap: "break-word", // Alternative for better word-breaking
              }}
            >
              {msg.message}
            </Box>
          );
        })}
      </Box>

      {/* Input Box */}
      <Box sx={{ p: 2, borderTop: 1, borderColor: "divider", display: "flex", gap: 1, bgcolor: "white" }}>
        <TextField
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          fullWidth variant="outlined" placeholder="Type your message..." value={newMessage} onChange={(e) => setNewMessage(e.target.value)} />
        <Button variant="contained" color="primary" onClick={sendMessage}>
          Send
        </Button>
      </Box>
    </Paper>
  );
};

export default ChatWindow;
