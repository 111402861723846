import React, { useState, useEffect } from "react";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
  Alert,
  Grid,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom"; // Ensure useLocation is imported
import CircularProgress from "@mui/material/CircularProgress";
import { AuthApi, OnBoardingApi } from "../../apis"; // Adjust the import path as needed
import HirioLogo from "../../assets/images/HirioBlue.png";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  // password: yup
  //     .string()
  //     .required('Password is required'),
});

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLinkedInLogin = () => {
    // Get the backend base URL from the environment variable
    const apiBaseUrl = "https://api.hirio.app" || "http://localhost:4500";
    window.location.href = `${apiBaseUrl}/auth/linkedin`;
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    localStorage.clear();
  }, []);
  // Extract the email from URL params
  const queryParams = new URLSearchParams(location.search); // Get search params from the current URL
  const emailFromUrl = queryParams.get("email") || ""; // Default to empty string if not found
  const isLinkedInUserFromUrl = queryParams.get("is_LinkedinUser") === "true";
  const formik = useFormik({
    initialValues: {
      email: emailFromUrl,
      password: "",
      is_linkedin_user: isLinkedInUserFromUrl,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError(null);
      try {
        const res = await AuthApi.login(values);

        if (res?.data?.status === 200) {
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          localStorage.setItem("is_onboarding", res.data.data.is_onboarding);
          let is_onboarding = res.data.data.is_onboarding || false;

          if (res.data.data.user.role.name === "employee") {
            if (is_onboarding) {
              window.location.href = "/v1/employee/home";
              // navigate('/v1/employee/home');
            } else {
              window.location.href = "/v1/employee/preferences";
            }
          } else if (res.data.data.user.role.name === "recruiter") {
            if (is_onboarding) {
              window.location.href = "/v1/recruiter/create-job-post/step1";
            } else {
              window.location.href = "/v1/recruiter/recruiter-profile";
            }
          } else {
            navigate("/");
          }
        } else {
          setError(
            "Login failed. Please check your credentials and try again."
          );
        }
      } catch (err) {
        console.error("Error occurred:", err);
        setError("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });
  // Automatically submit the form if email and password are provided in the URL
  useEffect(() => {
    if (emailFromUrl && isLinkedInUserFromUrl) {
      formik.submitForm(); // Programmatically trigger form submission
    }
  }, [emailFromUrl, isLinkedInUserFromUrl, formik]);
  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        backgroundImage: "linear-gradient(to right, #FFFFFF, #E4E4E4)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Logo Section */}
      <Box
        component={Link}
        href="/"
        sx={{
          marginBottom: 3, // Space between the logo and the form box
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={HirioLogo} alt="logo" style={{ width: "100px" }} />
      </Box>

      {/* Form Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          padding: 3,
          borderRadius: 0,
          width: "30%",
          height: "100%",
          maxWidth: "600px",
          "@media (max-width: 1000px)": { width: "40%" },
          "@media (max-width: 800px)": { width: "50%" },
          "@media (max-width: 400px)": { width: "75%" },
        }}
      >
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            component="h2"
            variant="subtitle1"
            sx={{
              alignSelf: "flex-start",
              fontWeight: "bold",
              fontSize: "20px",
              width: "100%",
            }}
          >
            {t("landing.Login")}
          </Typography>
          <Typography
            component="h2"
            variant="subtitle1"
            sx={{ alignSelf: "flex-start", fontSize: "12px" }}
          >
            {t("auth.login.subheading")}
          </Typography>
          <Box
            sx={{
              mt: 1,
              height: "380px",
              width: "400px",
              width: "100%",
              height: "100%",
            }}
          >
            {/* <Button
                                startIcon={<LinkedInIcon />}
                                fullWidth
                                variant="outlined"
                                onClick={handleLinkedInLogin}
                                sx={{ mt: 1, mb: 2, backgroundColor: 'white', color: '#2A4B9B', padding: '10px', borderColor: "lightgrey", '&:hover': { borderColor: 'lightgrey' } }}
                            >
                                <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '12px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '1px' }}>
                                    Continue with LinkedIn
                                </Typography>
                            </Button> */}
            {/* <Box sx={{ mt: 1, textAlign: 'center', marginBottom: '10px', position: 'relative' }}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        borderBottom: '1px solid lightgrey',
                                        lineHeight: 0,
                                    }}
                                />
                                <Typography variant="body2" sx={{ backgroundColor: '#fff', paddingX: '10px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1, color: 'grey', fontSize: "12px" }}>
                                    or log in using
                                </Typography>
                            </Box> */}
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <form onSubmit={formik.handleSubmit} noValidate>
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  alignSelf: "flex-start",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "black",
                  paddingTop: "10px",
                }}
              >
                {t("employee.footer.placeholder")}
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                id="email"
                name="email"
                placeholder={t("employee.footer.placeholder")}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{
                  margin: "5px 0",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#8EFFC2",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#8EFFC2",
                    },
                  },
                }}
              />
              {!isLinkedInUserFromUrl && (
                <>
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      alignSelf: "flex-start",
                      fontSize: "12px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: "black",
                      paddingTop: "10px",
                    }}
                  >
                    {t("auth.login.password")}
                  </Typography>
                  <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    placeholder={t("auth.login.password")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{ p: "6px" }}
                          >
                            {showPassword ? (
                              <VisibilityOff sx={{ fontSize: "18px" }} />
                            ) : (
                              <Visibility sx={{ fontSize: "18px" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      margin: "5px 0",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#8EFFC2",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#8EFFC2",
                        },
                      },
                    }}
                  />
                </>
              )}
              <Button
                type="submit"
                fullWidth
                variant="standard"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#8EFFC2",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#8EFFC2",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      alignSelf: "flex-start",
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: "#2C2C2C",
                      padding: "2px",
                    }}
                  >
                    {t("auth.login.btn")}
                  </Typography>
                )}
              </Button>
            </form>
            <Box sx={{ mt: 1, display: "flex" }}>
              <Typography
                variant="body2"
                sx={{
                  mr: 0.5,
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "11px",
                }}
              >
                {t("auth.login.noaccount")}
              </Typography>
              <Typography
                variant="body2"
                component={Link}
                href="/register"
                sx={{
                  mr: 15,
                  textDecoration: "none",
                  color: "#8EFFC2",
                  fontSize: "11px",
                }}
              >
                {t("auth.login.create")}
              </Typography>
              <Typography
                variant="body2"
                component={Link}
                href="/forgot-password"
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "11px",
                  ml: { xs: -12, sm: -4, md: 1 },
                }}
              >
                {t("auth.login.Forgot Password?")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
