"use client";

import React, { useState, useEffect } from "react";
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Link,
    Alert,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import HirioLogo from "../../assets/images/HirioBlue.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const validationSchema = yup.object({
    password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
});

const ResetPassword = () => {
    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { token } = useParams();

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            setError(null);
            setSuccessMessage(null);
            try {
                const res = await AuthApi.resetPassword(
                    token,
                    values.password,
                    values.confirmPassword,
                );
                if (res?.data?.status === 200) {
                    setSuccessMessage("Your password has been reset successfully.");
                    // Redirect to login page after 3 seconds
                    window.location.href = "/login";
                } else {
                    setError("Failed to reset password. Please try again.");
                }
            } catch (err) {
                setError("An error occurred. Please try again.");
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <Container
            component="main"
            maxWidth="xl"
            sx={{
                backgroundImage: "linear-gradient(to right, #FFFFFF, #E4E4E4)",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box component={Link} href="/" sx={{ marginBottom: 3, display: "flex", justifyContent: "center" }}>
                <img src={HirioLogo} alt="logo" style={{ width: "100px" }} />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    padding: 3,
                    borderRadius: 0,
                    width: "30%",
                    maxWidth: "600px",
                    "@media (max-width: 1000px)": { width: "40%" },
                    "@media (max-width: 800px)": { width: "50%" },
                    "@media (max-width: 400px)": { width: "75%" },
                }}
            >
                <Typography component="h2" variant="subtitle1" sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    {t("ResetPassword.title")}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
                    {t("ResetPassword.description")}
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                {successMessage && <Alert severity="success">{successMessage}</Alert>}
                <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="password"
                        name="password"
                        type="password"
                        placeholder={t("ResetPassword.newPassword")}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        sx={{
                            margin: "5px 0",
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#8EFFC2",
                                },
                            },
                        }}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        placeholder={t("ResetPassword.confirmPassword")}
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        sx={{
                            margin: "5px 0",
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#8EFFC2",
                                },
                            },
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="standard"
                        disabled={loading}
                        sx={{ mt: 3, mb: 2, backgroundColor: "#8EFFC2", color: "white" }}
                    >
                        {loading ? "Resetting..." : t("ResetPassword.resetButton")}
                    </Button>
                </form>
                <Link href="/login" variant="body2" sx={{ color: "#8EFFC2" }}>
                    {t("ResetPassword.backToLogin")}
                </Link>
            </Box>
        </Container>
    );
};

export default ResetPassword;
