import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import HirioLogo from "../../assets/images/HirioBlue.png";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email").required("Email is required"),
});

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError(null);
      setSuccessMessage(null);
      try {
        const res = await AuthApi.forgotPassword(values);
        if (res?.data?.status === 200) {
          setSuccessMessage("A password reset link has been sent to your email.");
        } else {
          setError("Failed to send reset link. Please try again.");
        }
      } catch (err) {
        setError("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        backgroundImage: "linear-gradient(to right, #FFFFFF, #E4E4E4)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box component={Link} href="/" sx={{ marginBottom: 3, display: "flex", justifyContent: "center" }}>
        <img src={HirioLogo} alt="logo" style={{ width: "100px" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          padding: 3,
          borderRadius: 0,
          width: "30%",
          maxWidth: "600px",
          "@media (max-width: 1000px)": { width: "40%" },
          "@media (max-width: 800px)": { width: "50%" },
          "@media (max-width: 400px)": { width: "75%" },
        }}
      >
        <Typography component="h2" variant="subtitle1" sx={{ fontWeight: "bold", fontSize: "20px" }}>
          {t("auth.forgotPassword.title")}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
          {t("auth.forgotPassword.description")}
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <TextField
            fullWidth
            margin="normal"
            id="email"
            name="email"
            placeholder={t("auth.forgotPassword.placeholder")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{
              margin: "5px 0",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#8EFFC2",
                },
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="standard"
            disabled={loading}
            sx={{ mt: 3, mb: 2, backgroundColor: "#8EFFC2", color: "white" }}
          >
            {loading ? "Sending..." : t("auth.forgotPassword.button")}
          </Button>
        </form>
        <Link href="/login" variant="body2" sx={{ color: "#8EFFC2" }}>
          {t("auth.forgotPassword.backToLogin")}
        </Link>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
