import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  Box,
  Chip,
  Grid,
  Typography,
} from "@mui/material";

// const ExperienceForm = ({
//   placeHolder,
//   specialties,
//   experienceYears,
//   formik,
//   selectedSpecialty,
//   handleSpecialtyChange,
//   handleChipClick,
//   getSpecialityColor,
// }) => {
//   return (
//     <Box>
//       <Typography variant="subtitle1" sx={{ fontWeight: "600", mt: -0.6 }}>
//         Profession
//       </Typography>

//       <FormControl fullWidth>
//         <Select
//           name={placeHolder}
//           value={selectedSpecialty || ""}
//           onChange={handleSpecialtyChange}
//           displayEmpty
//           renderValue={(selected) => {
//             if (!selected || selected.length === 0) {
//               return <span style={{ color: "black" }}>{placeHolder}</span>;
//             }
//             return selected.replace(/([A-Z])/g, " $1").trim();
//           }}
//         >
//           <MenuItem value="" disabled>
//             <em>Select Specialties</em>
//           </MenuItem>
//           {Object.keys(specialties).map((specialtyCategory) => (
//             <MenuItem key={specialtyCategory} value={specialtyCategory}>
//               {specialtyCategory.replace(/([A-Z])/g, " $1").trim()}
//             </MenuItem>
//           ))}
//         </Select>
//         {formik?.touched?.specialties && formik?.errors?.specialties && (
//           <Typography variant="body2" color="error">
//             {formik.errors.specialties}
//           </Typography>
//         )}
//       </FormControl>

//       {/* Display Subcategories as Chips */}
//       {selectedSpecialty && specialties[selectedSpecialty] && (
//         <Box mt={2}>
//           <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
//             Specialties
//           </Typography>
//           <Grid container spacing={1}>
//             {specialties[selectedSpecialty].map((subcategory) => (
//               <>
//                 {console.log(
//                   "🚀 ~ specialties[selectedSpecialty]:",
//                   selectedSpecialty
//                 )}
//                 <Grid item key={subcategory}>
//                   <Chip
//                     label={subcategory.replace(/([A-Z])/g, " $1").trim()}
//                     onClick={() => handleChipClick(subcategory)}
//                     color={
//                       experienceYears[selectedSpecialty]?.[subcategory]
//                         ? "primary"
//                         : "default"
//                     }
//                     sx={{
//                       mr: 0,
//                       mb: 1,
//                       backgroundColor: getSpecialityColor(subcategory), // Ensure color is applied
//                       "&:hover": {
//                         backgroundColor: getSpecialityColor(subcategory), // Hover effect with same color
//                       },
//                       padding: "8px 16px",
//                     }}
//                     style={{
//                       backgroundColor: getSpecialityColor(subcategory), // Inline style for background color
//                     }}
//                   />
//                 </Grid>
//               </>
//             ))}
//           </Grid>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ExperienceForm;

const ExperienceForm = ({
  placeHolder,
  specialties,
  experienceYears,
  formik,
  selectedSpecialty,
  handleSpecialtyChange,
  handleChipClick,
  getSpecialityColor,
  selectedCategories, // Received from parent
}) => {
  
  return (
    <Box>
      <Typography variant="subtitle1" sx={{ fontWeight: "600", mt: -0.6 }}>
        Profession
      </Typography>

      <FormControl fullWidth>
        <Select
          name={placeHolder}
          value={selectedSpecialty || ""}
          onChange={handleSpecialtyChange}
          displayEmpty
          renderValue={(selected) => {
            if (!selected || selected.length === 0) {
              return <span style={{ color: "black" }}>{placeHolder}</span>;
            }
            return selected.replace(/([A-Z])/g, " $1").trim();
          }}
        >
          <MenuItem value="" disabled>
            <em>Select Specialties</em>
          </MenuItem>
          {Object.keys(specialties).map((specialtyCategory) => (
            <MenuItem key={specialtyCategory} value={specialtyCategory}>
              {specialtyCategory.replace(/([A-Z])/g, " $1").trim()}
            </MenuItem>
          ))}
        </Select>
        {formik?.touched?.specialties && formik?.errors?.specialties && (
          <Typography variant="body2" color="error">
            {formik.errors.specialties}
          </Typography>
        )}
      </FormControl>

      {/* Display Subcategories as Chips */}
      {selectedSpecialty && specialties[selectedSpecialty] && (
        <Box mt={2}>
          <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
            Specialties
          </Typography>
          <Grid container spacing={1}>
            {specialties[selectedSpecialty].map((subcategory) => (
              <>
                <Grid item key={subcategory}>
                  <Chip
                    label={subcategory.replace(/([A-Z])/g, " $1").trim()}
                    onClick={() => handleChipClick(subcategory)} // Trigger chip selection toggle
                    color={
                      experienceYears[selectedSpecialty]?.[subcategory]
                        ? "primary"
                        : "default"
                    }
                    sx={{
                      mr: 0,
                      mb: 1,
                      backgroundColor: getSpecialityColor(subcategory),
                      "&:hover": {
                        backgroundColor: getSpecialityColor(subcategory),
                      },
                      padding: "8px 16px",
                    }}
                    style={{
                      backgroundColor: getSpecialityColor(subcategory),
                    }}
                    selected={selectedCategories[subcategory] || false} // Use selectedCategories state for chip selection
                  />
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ExperienceForm;
