import React, { useState, useEffect } from "react";
import { Typography, Container, Tabs, Tab, Box } from "@mui/material";
import { JobApi } from "../../apis";
import JobCard from "../JobOffers/EmployeeCard";
import { useTranslation } from "react-i18next";

export default function JobCandidates() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [jobDataArray, setJobDataArray] = useState([]);
  const [pendingJobData, setPendingJobData] = useState([]);
  const [acceptedJobData, setAcceptedJobData] = useState([]);
  const [rejectedJobData, setRejectedJobData] = useState([]);
  // const [jobId, setJobId] = useState(null);
  const fetchJobPosts = async () => {
    try {
      const res = await JobApi.getAllJobPostUsers();
      if (res && res.data && res.data.data) {
        const parsedJobPosts = res.data.data.map((job) => ({
          ...job,
          topSpecialities: parseJSON(job.profile.top_specialities),
          employmentType: parseJSON(job.profile.employment_type),
          salaryRange: parseJSON(job.profile.salary_range),
        }));

        setJobDataArray(parsedJobPosts);
        setPendingJobData(
          parsedJobPosts.filter((job) => job.job_offer_status === "Pending")
        );
        setAcceptedJobData(
          parsedJobPosts.filter((job) => job.job_offer_status === "Accepted")
        );
        setRejectedJobData(
          parsedJobPosts.filter((job) => job.job_offer_status === "Rejected")
        );
      } else {
        console.error(
          "Error fetching job posts:",
          res ? res.message : "No response received"
        );
      }
    } catch (error) {
      console.error("Error fetching job posts:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchJobPosts();
  }, []);

  const parseJSON = (jsonString) => {
    try {
      if (typeof jsonString === "object") {
        return jsonString;
      }
      return jsonString ? JSON.parse(jsonString) : {};
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "22px",
          marginBottom: "10px",
          marginTop: "40px",
        }}
      >
        {t("recruiter.home.nav_link.Candidates")}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {t("recruiter.candidates.subheading")}
      </Typography>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          "& .MuiTabs-flexContainer": { justifyContent: "space-between" },
          minWidth: "240px",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="job offer tabs"
          indicatorColor="secondary"
        >
          <Tab
            label={` ${t("employee.job-offers.tabpending")} (${
              pendingJobData.length
            })`}
            sx={{
              fontSize: { xs: "10px", sm: "12px", md: "14px" },
              color: tabValue === 0 ? "primary.main" : "black",
              width: "33.33%",
              fontWeight: tabValue === 0 ? "bold" : "normal",
            }}
          />
          <Tab
            label={`${t("employee.job-offers.tabaccepted")} (${
              acceptedJobData.length
            })`}
            sx={{
              fontSize: { xs: "10px", sm: "12px", md: "14px" },
              color: tabValue === 1 ? "primary.main" : "black",
              width: "33.33%",
              fontWeight: tabValue === 1 ? "bold" : "normal",
            }}
          />
          <Tab
            label={`${t("employee.job-offers.tabrejected")} (${
              rejectedJobData.length
            })`}
            sx={{
              fontSize: { xs: "10px", sm: "12px", md: "14px" },
              color: tabValue === 2 ? "primary.main" : "black",
              width: "33.33%",
              fontWeight: tabValue === 2 ? "bold" : "normal",
            }}
          />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <Box sx={{ pt: 2 }}>
          {pendingJobData.length > 0 ? (
            pendingJobData.map((offer) => (
              <JobCard
                key={offer.id}
                offer={offer}
                offerId={offer}
                isPending={true}
                isAccepted={false}
                isRejected={false}
              />
            ))
          ) : (
            <Typography sx={{ ml: { md: 11 } }}>
              {t("recruiter.candidates.tabpending")}
            </Typography>
          )}
        </Box>
      )}

      {tabValue === 1 && (
        <Box sx={{ pt: 2 }}>
          {acceptedJobData.length > 0 ? (
            acceptedJobData.map((offer) => (
              <JobCard
                key={offer.id}
                offer={offer}
                offerId={offer}
                // jobId={jobId}
                isPending={false}
                isAccepted={true}
                isRejected={false}
              />
            ))
          ) : (
            <Typography sx={{ ml: { md: 11 } }}>
              {t("recruiter.candidates.tabaccepted")}{" "}
            </Typography>
          )}
        </Box>
      )}

      {tabValue === 2 && (
        <Box sx={{ pt: 2 }}>
          {rejectedJobData.length > 0 ? (
            rejectedJobData.map((offer) => (
              <JobCard
                key={offer.id}
                offer={offer}
                offerId={offer}
                isPending={false}
                isAccepted={false}
                isRejected={true}
              />
            ))
          ) : (
            <Typography sx={{ ml: { md: 11 } }}>
              No Candidates available in rejected{" "}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}
