import { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_API_BASE_URL}`);
    // userConnected
    const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null;
    if (userId)
      newSocket.emit('userConnected', { userId: userId });
      newSocket.emit('updateSocketId', ({ userId, socketId: newSocket.id }));

    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
