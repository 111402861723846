// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PreviewAndSubmit from '../../Pages/Recruiter/Onboarding/preview&submit/preview&submit';
import RecruiterProfile from '../../Pages/Recruiter/Onboarding/index';
import CompanyProfile from '../../Pages/Recruiter/Onboarding/CompanyProfile/CompanyProfile';
import RecruiterSidebar from '../../Pages/sidebar/recruiterSidebar';
import DashBoard from '../../Pages/Recruiter/Panel/Home';
import JobDetails from '../../Pages/Recruiter/Panel/JobDetails/index';
import CreateJobPost from '../../Pages/Recruiter/Panel/CreateJobPost';
import MyProfilePage from '../../Pages/Recruiter/Panel/MyProfile';
import JobOffersPage from '../../Pages/Recruiter/Panel/JobOffers';
import MyJobsPage from '../../Pages/Recruiter/Panel/MyJobs';
import Chats from '../../Pages/Recruiter/Panel/Chat';
import { OnBoardingApi } from '../../apis';
import Loader from '../../components/GlobalComponent/Loader';
import Candidates from '../../Pages/Recruiter/Panel/Candidates';
import Support from '../../components/GlobalComponent/Support';

function RecruiterRoutes() {
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      try {
        const response = await OnBoardingApi.getOnBoarding();
        setOnboardingStatus(response?.data?.onboarding?.is_onboarding);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOnboardingStatus();
  }, []);



  if (loading) {
    return <div>
         <Loader loading={loading} />
    </div>;
  }

  if (error) {
    return <div>Error fetching onboarding status</div>;
  }

  return (
    <>
      {onboardingStatus ? (
        <Routes>
          <Route path="recruiter/*">
            <Route exact path="home" element={<DashBoard />} />
            <Route exact path="job-details/:id" element={<JobDetails />} />
            <Route exact path="create-job-post/step1" element={<CreateJobPost />} />
            <Route exact path="job-offers" element={<JobOffersPage />} />
            <Route exact path="candidates" element={<Candidates />} />
            <Route exact path="my-job-posts" element={<MyJobsPage />} />
            <Route  path="chat" element={<Chats />} />
            <Route exact path="my-profile" element={<MyProfilePage />} />
            <Route path="support" element={<Support />} />
            <Route path="*" element={<Navigate to="/v1/recruiter/home" replace />} />

          </Route>
        </Routes>
      ) : (
        <RecruiterSidebar>
          <Routes>
            <Route path="recruiter/*">
              <Route path="company-profile" element={<CompanyProfile />} />
              <Route path="recruiter-profile" element={<RecruiterProfile />} />
              <Route path="preview-and-submit" element={<PreviewAndSubmit />} />
              <Route path="*" element={<Navigate to="/v1/recruiter/home" replace />} />


            </Route>
          </Routes>
        </RecruiterSidebar>
      )}
    </>
  );
}

export default RecruiterRoutes;
