import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Container,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import ChangePassword from "../ChangePassword/changePassword";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { OnBoardingApi, AuthApi } from "../../apis";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NotificationsIcon from "@mui/icons-material/Notifications";
import JobContext from "../../context/JobContext";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../GlobalComponent/languageSwitcher";
import { useSocket } from "../../SocketProvider";

export default function Dashboard(props) {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [selectedNavItem, setSelectedNavItem] = useState("");
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation hook here
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { addNewMessage, newMessage } = React.useContext(JobContext);
  const [notifications, setNotifications] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);

  let user;

  try {
    const userString = localStorage.getItem("user");

    user = JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing user from localStorage:", error);
  }

  const isEmployee = user && user.role.name === "employee";
  const isRecruiter = user && user.role.name === "recruiter";

  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const socket = useSocket(); // Get socket instance from context

  const fetchOnBoardedUserData = async () => {
    try {
      const res = await OnBoardingApi.getOnBoarding();
      setUserData(res?.data?.profile);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching on-boarded user data:", error);
      setLoading(false);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await AuthApi.getNotifications();
      const data = response.data?.data || [];

      // Calculate unseen count
      const unseen = data.filter((notif) => !notif.isRead).length;

      setNotifications(data);
      setUnseenCount(unseen);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      await AuthApi.markNotificationAsRead();

      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) =>
          notif.id === notification.id ? { ...notif, isRead: true } : notif
        )
      );

      setUnseenCount(0);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();

    socket.on("unreadNotification", (data) => {
      setUnseenCount((prevCount) => prevCount + 1);
    });

    return () => {
      socket.off("unreadNotification");
    };
  }, []);
  useEffect(() => {
    fetchOnBoardedUserData();
  }, []);
  useEffect(() => {
    const pathname = location.pathname;

    if (isRecruiter) {
      setSelectedNavItem(getSelectedMenuItem(pathname));
    } else if (isEmployee) {
      setSelectedNavItem(getSelectedEmployeeMenuItem(pathname));
    }
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };

  const getSelectedMenuItem = (pathname) => {
    switch (pathname) {
      case "/v1/recruiter/home":
        // return "Home";
        return t("recruiter.home.nav_link.Home");
      case "/v1/recruiter/candidates":
        // return "Candidates";
        return t("recruiter.home.nav_link.Candidates");

      case "/v1/recruiter/my-job-posts":
        // return "My Job Posts";
        return t("recruiter.home.nav_link.My Job Posts");

      case "/v1/recruiter/chat":
        // return "Chat";
        return t("recruiter.home.nav_link.Chat");

      default:
        return "";
    }
  };
  const getSelectedEmployeeMenuItem = (pathname) => {
    switch (pathname) {
      case "/v1/employee/home":
        // return "Home";
        return t("employee.home.navlink_Home");
      case "/v1/employee/job-offers":
        // return "Job Offers";
        return t("employee.home.navlink_Job Offers");

      case "/v1/employee/my-profile":
        // return "My Profile";
        return t("employee.home.navlink_My Profile");

      case "/v1/employee/chat":
        // return "Chat";
        return t("employee.home.navlink_Chat");

      default:
        return "";
    }
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const markNotificationAsRead = async () => {
    try {
      await AuthApi.markNotificationAsRead();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };
  const handleNotificationMenu = (event) => {
    setAnchorE2(event.currentTarget);
    markNotificationAsRead();
  };
  const handleCloseNotificationMenu = () => {
    setAnchorE2(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRecruiterNavigationItemClick = (text) => {
    setSelectedNavItem(text);
    let path = "";

    switch (text) {
      case t("employee.home.navlink_Home"):
        path = "/v1/recruiter/home";
        break;
      // case "Job Offers":
      //   path = "/v1/recruiter/job-offers";
      //   break;
      case t("recruiter.home.nav_link.Candidates"):
        path = "/v1/recruiter/candidates";
        break;
      case t("recruiter.home.nav_link.My Job Posts"):
        path = "/v1/recruiter/my-job-posts";
        break;
      case t("recruiter.home.nav_link.Chat"):
        path = "/v1/recruiter/chat";
        break;
      default:
        console.error(`No route defined for ${text}`);
        return; // Exit if no valid route is found
    }

    navigate(path);
  };
  const handleEmployeeNavigationItemClick = (text) => {
    setSelectedNavItem(text);
    let path = "";

    switch (text) {
      // case "Home":
      case t("employee.home.navlink_Home"):
        path = "/v1/employee/home";
        break;
      // case "Job Offers":
      case t("employee.home.navlink_Job Offers"):
        path = "/v1/employee/job-offers";
        break;

      // case "My Profile":
      case t("employee.home.navlink_My Profile"):
        path = "/v1/employee/my-profile";
        break;
      // case "Chat":
      case t("employee.home.navlink_Chat"):
        path = "/v1/employee/chat";
        break;
      default:
        console.error(`No route defined for ${text}`);
        return; // Exit if no valid route is found
    }

    navigate(path);
  };
  const handleOpenChangePassword = () => {
    setChangePasswordOpen(true);
    handleClose(); // Close the menu when opening change password dialog
  };

  const handleCloseChangePassword = () => {
    setChangePasswordOpen(false);
  };
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    if (isTablet) {
      if (currentScrollPos > prevScrollPos) {
        setVisible(false);
      } else if (currentScrollPos < prevScrollPos) {
        setVisible(false);
      }

      if (currentScrollPos <= 100) {
        setVisible(true); // If at the top of the page, show the navbar
      }
    } else {
      // Desktop mode logic
      if (currentScrollPos > 100 && currentScrollPos > prevScrollPos) {
        setVisible(true); // If scrolling down on desktop, show the navbar
      } else if (currentScrollPos > 100 && currentScrollPos < prevScrollPos) {
        setVisible(true); // If scrolling up on desktop, show the navbar
      } else if (currentScrollPos <= 100) {
        setVisible(true); // If at the top of the page, show the navbar
      }
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const drawerWidth = 240;
  const drawer = (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerClose}
      ModalProps={{ keepMounted: true }} // Better open performance on mobile
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          bgcolor: "primary.main",
          color: "text.secondary",
        },
      }}
    >
      {isRecruiter && (
        <Link to="/v1/recruiter/home" style={{ textDecoration: "none" }}>
          <Box sx={{ marginRight: "2%", m: 2 }}>
            <img src={Logo} alt="logo" style={{ width: "100px" }} />
          </Box>
        </Link>
      )}

      {isEmployee && (
        <Link to="/v1/employee/home" style={{ textDecoration: "none" }}>
          <Box sx={{ marginRight: "2%", m: 2 }}>
            <img src={Logo} alt="logo" style={{ width: "100px" }} />
          </Box>
        </Link>
      )}
      <Divider />
      {isRecruiter && (
        <List>
          {["Home", "Candidates", "My Job Posts", "Chat"].map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                // selected={selectedNavItem === text}
                selected={
                  selectedNavItem === t(`recruiter.home.nav_link.${text}`)
                }
                onClick={() =>
                  handleRecruiterNavigationItemClick(
                    t(`recruiter.home.nav_link.${text}`)
                  )
                }
              >
                <ListItemText
                  //  primary={text}
                  primary={t(`recruiter.home.nav_link.${text}`)}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      {isEmployee && (
        <List>
          {["Home", "Job Offers", "My Profile", "Chat"].map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                // selected={selectedNavItem === text}
                selected={
                  selectedNavItem === t(`employee.home.navlink_${text}`)
                }
                onClick={() =>
                  handleEmployeeNavigationItemClick(
                    t(`employee.home.navlink_${text}`)
                  )
                }
              >
                <ListItemText primary={t(`employee.home.navlink_${text}`)} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Drawer>
  );
  const recruiterNavigationList = (
    <Box sx={{ display: "flex" }}>
      {["Home", "Candidates", "My Job Posts", "Chat"].map((text) => (
        <ListItemButton
          disableRipple
          key={text}
          selected={selectedNavItem === t(`recruiter.home.nav_link.${text}`)}
          onClick={() =>
            handleRecruiterNavigationItemClick(
              t(`recruiter.home.nav_link.${text}`)
            )
          }
          sx={{
            ml: 1,
            color:
              selectedNavItem === t(`recruiter.home.nav_link.${text}`)
                ? scrolled
                  ? "secondary.main"
                  : "secondary.main"
                : scrolled
                  ? "white"
                  : "white",
            "&:hover": {
              cursor:
                selectedNavItem === t(`recruiter.home.nav_link.${text}`)
                  ? "auto"
                  : "pointer",
            },
            "&.Mui-selected": {
              backgroundColor: scrolled ? "transparent" : "transparent",
            },
          }}
        >
          <ListItemText
            primary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* {text} */}
                {t(`recruiter.home.nav_link.${text}`)}
                {text === "Chat" && newMessage && (
                  <FiberManualRecordIcon
                    sx={{
                      fontSize: "0.8rem",
                      color: "secondary.main",
                      ml: 1,
                    }}
                  />
                )}
              </Box>
            }
          />
        </ListItemButton>
      ))}
    </Box>
  );

  const employeeNavigationList = (
    <Box sx={{ display: "flex" }}>
      {["Home", "Job Offers", "My Profile", "Chat"].map((text) => (
        <ListItemButton
          disableRipple
          key={text}
          selected={selectedNavItem === t(`employee.home.navlink_${text}`)}
          onClick={() =>
            handleEmployeeNavigationItemClick(
              t(`employee.home.navlink_${text}`)
            )
          }
          sx={{
            ml: 1,
            color:
              selectedNavItem === t(`employee.home.navlink_${text}`)
                ? scrolled
                  ? "secondary.main"
                  : "secondary.main"
                : scrolled
                  ? "white"
                  : "white",
            "&:hover": {
              cursor:
                selectedNavItem === t(`employee.home.navlink_${text}`)
                  ? "auto"
                  : "pointer",
            },
            "&.Mui-selected": {
              backgroundColor: scrolled ? "transparent" : "transparent",
            },
          }}
        >
          <ListItemText
            primary={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {t(`employee.home.navlink_${text}`)}
                {text === "Chat" && newMessage && (
                  <FiberManualRecordIcon
                    sx={{
                      fontSize: "0.8rem",
                      color: "secondary.main",
                      ml: 1,
                    }}
                  />
                )}
              </Box>
            }
          />
        </ListItemButton>
      ))}
    </Box>
  );

  const Redirection = (currentPath, notificationType) => {
    let newPath = currentPath;
    const basePath = currentPath.split("/").slice(0, 3).join("/");

    if (notificationType === "chat") {
      if (currentPath.includes("job-offers")) {
        newPath = `${basePath}/chat`;
      } else if (!currentPath.includes("chat")) {
        newPath = `${basePath}/chat`;
      }
    } else if (notificationType === "job") {
      if (currentPath.includes("chat")) {
        newPath = `${basePath}/job-offers`;
      } else if (!currentPath.includes("job-offers")) {
        newPath = `${basePath}/job-offers`;
      }
    }

    return newPath;
  };

  const handelSupport = () => {
    if (isRecruiter) {
      navigate("/v1/recruiter/support");
    }
    else {
      navigate("/v1/employee/support");
    }
  };

  useEffect(() => {
    // receiveSupportMessageNotification
    if (socket) {
      socket.on("receiveSupportMessageNotification", (notification) => {
        console.log("receiveSupportMessageNotification", notification);
        setNotifications((prevNotifications) => [...prevNotifications, notification]);
        setUnseenCount((prevUnseenCount) => prevUnseenCount + 1);
      });
    }
    return () => {
      socket.off("receiveSupportMessageNotification");
    };
  }, [socket, setNotifications, setUnseenCount]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          top: visible ? 0 : -100,
          backgroundColor: scrolled ? "primary.main" : "primary.main",
          color: scrolled ? theme.palette.primary.main : "#FFF",
        }}
      >
        <Toolbar
          sx={{
            position: "relative",
          }}
        >
          {isTablet && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              sx={{ mr: 2 }}
            >
              <MenuIcon sx={{ color: "secondary.main" }} />
            </IconButton>
          )}
          {!isTablet && (
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Box sx={{ marginRight: 5, my: 2 }}>
                <img
                  src={scrolled ? Logo : Logo}
                  alt="logo"
                  style={{ width: "100px" }}
                />
              </Box>
            </Link>
          )}
          {!isTablet &&
            (isEmployee ? employeeNavigationList : recruiterNavigationList)}
          {/* notification button */}

          <Box sx={{ ml: "auto" }}>
            <IconButton
              id="menu-appbarNotification"
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleNotificationMenu}
              sx={{
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                width: "50px",
              }}
            >
              <div style={{ position: "relative", display: "inline-block" }}>
                <NotificationsIcon
                  sx={{
                    fontSize: "1.5rem",
                    color: "secondary.main",
                    cursor: "pointer",
                  }}
                  onClick={handleNotificationClick}
                />
                {unseenCount > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      minWidth: "16px",
                      height: "16px",
                      backgroundColor: "red",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px solid white",
                    }}
                  >
                    {unseenCount}
                  </span>
                )}
              </div>
            </IconButton>

            <Menu
              id="menu-appbarNotification"
              anchorEl={anchorE2}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorE2)}
              onClose={handleCloseNotificationMenu}
              sx={{
                marginTop: "40px",
              }}
            >
              {/* {notifications.length === 0 ? (
                <MenuItem>No Notification</MenuItem>
              ) : (
                notifications?.map((item) => {
                  console.log("🚀 ~ notifications?.map ~ item:", item);
                  const formattedTime = new Date(item.createdAt).toLocaleString(
                    "en-US",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  );

                  return (
                    <MenuItem
                      key={item.id}
                      p={4}
                      onClick={() => {
                        handleCloseNotificationMenu();

                        const currentPath = window.location.pathname;
                        let newPath = currentPath;
                        console.log(
                          "🚀 ~ notifications?.map ~ currentPath:",
                          currentPath
                        );

                        const basePath = currentPath
                          .split("/")
                          .slice(0, 3)
                          .join("/");

                        if (item.notification_type === "chat") {
                          if (currentPath.includes("job-offers")) {
                            newPath = `${basePath}/chat`;
                          } else if (!currentPath.includes("chat")) {
                            newPath = `${basePath}/chat`;
                          }
                        } else if (item.notification_type === "job") {
                          if (currentPath.includes("chat")) {
                            newPath = `${basePath}/job-offers`;
                          } else if (!currentPath.includes("job-offers")) {
                            newPath = `${basePath}/job-offers`;
                          }
                        }

                        navigate(newPath);
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textTransform: "none",
                          color: "primary.main",
                          padding: "2px",
                        }}
                      >
                        {item.message}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                          color: "text.primary",
                          marginTop: "2px",
                        }}
                      >
                        {formattedTime}
                      </Typography>
                    </MenuItem>
                  );
                })
              )} */}

              {notifications.length === 0 ? (
                <MenuItem>No Notification</MenuItem>
              ) : (
                notifications?.map((item) => {
                  const formattedTime = new Date(item.createdAt).toLocaleString(
                    "en-US",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  );

                  return (
                    <MenuItem
                      key={item.id}
                      p={4}
                      onClick={() => {
                        handleCloseNotificationMenu();

                        const currentPath = window.location.pathname;

                        const newPath = Redirection(
                          currentPath,
                          item.notification_type
                        );

                        navigate(newPath);
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textTransform: "none",
                          color: "primary.main",
                          padding: "2px",
                        }}
                      >
                        {item.message}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                          color: "text.primary",
                          marginTop: "2px",
                        }}
                      >
                        {formattedTime}
                      </Typography>
                    </MenuItem>
                  );
                })
              )}
            </Menu>

            {isRecruiter && (
              <Button
                type="submit"
                variant="outlined"
                onClick={() => navigate("/v1/recruiter/create-job-post/step1")}
                sx={{
                  mt: 3,
                  mb: 2,
                  borderColor: "primary.main",
                  color: "primary.main",
                  backgroundColor: "secondary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                    borderColor: "primary.dark", // Adjust as needed for hover effect
                    color: "primary.dark", // Adjust as needed for hover effect
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "primary.main",
                    padding: "2px",
                  }}
                >
                  {t("employee.home.createJobPost")}
                </Typography>
              </Button>
            )}

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              sx={{
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                width: "50px",
              }}
            >
              {userData && userData.profile_photo_url ? (
                <Avatar
                  src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData.profile_photo_url}`}
                  alt={userData.profile_photo_url}
                  sx={{ width: 35, height: 35 }}
                />
              ) : (
                <AccountCircle
                  sx={{
                    color: "gray",
                    width: 40,
                    height: 40,
                  }}
                />
              )}
            </IconButton>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              marginTop: "50px",
            }}
          >
            {isRecruiter && (
              <Box>
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "primary.main",
                    padding: "2px",
                    textAlign: "center",
                  }}
                >
                  {userData?.full_name || ""}
                </Typography>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate("/v1/recruiter/my-profile");
                  }}
                >
                  {t("employee.home.viewProfile")}
                </MenuItem>
              </Box>
            )}
            {isEmployee && (
              <Box>
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "primary.main",
                    padding: "2px",
                    textAlign: "center",
                  }}
                >
                  {userData?.full_name || ""}
                </Typography>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate("/v1/employee/my-profile");
                  }}
                >
                  {t("employee.home.viewProfile")}
                </MenuItem>
              </Box>
            )}
            <MenuItem onClick={handleOpenChangePassword}>
              {t("employee.home.changePassword")}
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              {t("employee.home.logout")}
            </MenuItem>
            <MenuItem
              onClick={() => { handelSupport() }}>
              {t("employee.home.support")}
            </MenuItem>
            <MenuItem>
              <LanguageSwitcher />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {isTablet && drawer}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container>{props.children}</Container>
      </Box>
      <Dialog open={changePasswordOpen} onClose={handleCloseChangePassword}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            ml={1}
            sx={{ fontWeight: "600", fontSize: "20px", ml: 2 }}
          >
            {t("employee.home.changePassword")}
          </Typography>

          <IconButton onClick={handleCloseChangePassword}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ChangePassword />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
