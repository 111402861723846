// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes,useNavigate ,Navigate} from 'react-router-dom';
import Preferences from '../../Pages/Employee/Onboarding/index';
import About from '../../Pages/Employee/Onboarding/about/about';
import Background from '../../Pages/Employee/Onboarding/background/background';
import PreviewAndSubmit from '../../Pages/Employee/Onboarding/preview&submit/preview&submit';
import EmployeeSidebar from '../../Pages/sidebar/employeeSidebar';
import Dashboard from '../../Pages/Employee/Panel/Home';
import MyProfilePage from '../../Pages/Employee/Panel/MyProfile';
import JobOffersPage from '../../Pages/Employee/Panel/JobOffers';
import Chats from '../../Pages/Employee/Panel/Chat';
import Support from '../../components/GlobalComponent/Support';
import { OnBoardingApi } from '../../apis';
import JobDetails from '../../Pages/Employee/Panel/JobDetails';
import Loader from '../../components/GlobalComponent/Loader';

function EmployeeRoutes() {
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      try {
        const response = await OnBoardingApi.getOnBoarding();
        setOnboardingStatus(response?.data?.onboarding?.is_onboarding);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOnboardingStatus();
  }, []);

  if (loading) {
    return <div>
         <Loader loading={loading} />
    </div>;
  }

  if (error) {
    localStorage.clear();
    navigate('/');
  }
  return (
    <>
        {onboardingStatus ? (
          <Routes>
            <Route path="employee/*" >
              <Route exact path="home" element={<Dashboard />} />
              <Route exact path="job-details/:id" element={<JobDetails />} />
              <Route exact path="job-offers" element={<JobOffersPage />} />
              <Route exact path="my-profile" element={<MyProfilePage />} />
              <Route exact path="resume" element={<MyProfilePage />} />
              <Route exact path="chat" element={<Chats />} />
              <Route path="support" element={<Support />} />
              {/* <Route path="*" element={<Navigate to="/v1/employee/home" replace />} /> */}
            </Route>
          </Routes>
           ) : (
          <EmployeeSidebar>
            <Routes>
              <Route path="employee/*" >
                <Route exact path="preferences/*" element={<Preferences />} />
                <Route exact path="about-you" element={<About />} />
                <Route exact path="background" element={<Background />} />
                <Route exact path="preview-and-submit" element={<PreviewAndSubmit />} />
                <Route path="*" element={<Navigate to="/v1/employee/home" replace />} />
              </Route>
            </Routes>
          </EmployeeSidebar>
     )}
    </>

  );
}

export default EmployeeRoutes;
