import React from "react";
import NavBar from "../../components/Global/NavBar";
import Footer from "../../components/Global/Footer";
import { Box, Container, Grid } from "@mui/material";
import SupportPage from "../Support";

const Support = () => {
    return (
        <div>
            <NavBar />
            <Box sx={{ backgroundColor: "white", minHeight: "100vh", mt: -6 }}>
                <Container>
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <SupportPage />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Footer />
        </div>
    );
}

export default Support;